.error-popup-container {
  padding: 0 5px;
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .cnt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #000000;
    }

    @media screen and (max-height: 540px) {
      .title {
        font-size: 16px;
      }
    }

    .description {
      text-align: center;
      margin: 0 10px 30px 10px;
      white-space: pre-line;

      .admin-payment-error {
        margin-left: -10px;
        text-align: left;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.05em;

        & p:not(:first-child) {
          margin: 5px 0;

          &:before {
            content: '';
            display: block;
            width: 12px;
            height: 2px;
            background: #F9E32E;
            position: relative;
            bottom: -10px;
            left: -15px;
            margin-right: 5px;
          }
        }
      }

      @media screen and (min-width: 959px) {
        .admin-payment-error {
          margin: 0;
          text-align: left;
          font-size: 18px;
        }
      }
      @media screen and (max-height: 360px) {
        .admin-payment-error {
          display: none;
        }
      }
      @media screen and (max-height: 400px) {
        .admin-payment-error {
          & p:nth-child(3) {
            display: none;
          }
        }
      }
      @media screen and (max-height: 490px) {
        .admin-payment-error {
          & p:nth-child(4) {
            display: none;
          }
        }
      }
      @media screen and (max-height: 540px) {
        .admin-payment-error {
          & p:first-child {
            margin-top: -10px;
          }
        }
      }
    }

    .cnt-button {
      position: absolute;
      width: 100%;
      left: 0;
      display: flex;
      justify-content: center;
      bottom: 20px;

      .btn-ok {
        background-color: white;
        padding: 10px;
        display: flex;
        justify-content: center;
        text-transform: none;
        border-radius: 0;
        border: 1px solid #662681;
        letter-spacing: -1px;
        width: 70%;
        color: black;
        font-family: Geometria, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }

    @media screen and (min-width: 959px) {
      .cnt-button {
        margin-bottom: 50px;
      }
    }
  }
}

