
.success-popup-container {
  padding: 0 5px;
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .cnt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #000000;
    }

    .description {
      text-align: center;
      margin: 0 10px 10px 10px;
    }

    @media screen and (max-height: 540px) {
      .title {
        font-size: 16px;
      }
      .description {
        font-size: 14px;
      }
    }

    .cnt-button {
      position: absolute;
      display: flex;
      justify-content: center;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 10px;

      .btn-ok {
        background-color: white;
        padding: 10px;
        display: flex;
        justify-content: center;
        text-transform: none;
        border-radius: 0;
        border: 1px solid #662681;
        letter-spacing: -1px;
        width: 70%;
        color: black;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }

    @media screen and (min-width: 959px) {
      .cnt-button {
        margin-bottom: 100px;
      }
    }
  }
}
