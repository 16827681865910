.switch-user-type {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    width: 100%;

    & > p {
      margin-top: 30px;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .outlined-btn {
        margin-top: 10px;
        text-transform: none;
        border-radius: 0;
        border: 2px solid #662681;
        letter-spacing: -1px;
        max-width: 400px;
      }
    }
  }
}

