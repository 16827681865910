.go-back-button {
  position: relative;
  left: 5%;
  width: 90%;

  &.abs {
    position: absolute;
    top: 59px;
    top: calc(constant(safe-area-inset-top) + 59px);
    top: calc(env(safe-area-inset-top) + 59px);
  }
}

.arrow-back {
  color: black !important;
  z-index: 20;
}

.white {
  color: white;
}

.bottom-margin {
  margin-bottom: 20px;
}

.modal-item-content {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
