.button {
  background: #FEEB17;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border: 1px solid #FEEB17;
  padding: 15px;
  width: 85%;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 129%;
  letter-spacing: -1px;
  align-items: center;
  text-align: center;
  text-transform: none;
  border-radius: 0;

  &.yellow {
    background: #FEEB17;
    width: 100%;
    padding: 10px;
    text-transform: none;
    font-size: 17px;

    &:hover {
      background: #FEEB17;
    }

    @media screen and (min-width: 959px) {
      &.yellow {
        width: 50%;
        padding: 10px;
      }
    }
  }

  &.green-bg {
    background: #00C850;
    color: white;
    border: none;
  }
}

.button-container {
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 5%;
  width: 100%;
  z-index: 5;
}


.yellow-button-container {
  display: flex;
  justify-content: center;

  @media screen and (min-width: 959px) {
    margin-top: 0;
    justify-content: left;
  }
}

.outlined-btn {
  margin-top: 10px;
  text-transform: none;
  border-radius: 0;
  border: 2px solid #662681;
  letter-spacing: -1px;

  .text {
    color: black;
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &.out-button {
    width: 90%;
    margin-bottom: 10px;
    padding: 10px;
  }
}

.score-to-rubbles {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.01em;
  font-weight: normal;
}

.green {
  color: #00C898;
}

.ul-img {
  padding-left: 0;
  li {
    list-style: none;
    padding-left: 40px;
    margin-bottom: 15px;
  }
}

.li-img-cnt {
  position: relative;

  .ul-title {
    font-weight: bold;
  }
}

.p {
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.01em;
}

@media screen and (min-width: 959px) {
  .p {
    font-size: 16px;
  }
}

ol.numbered-list {
  counter-reset: item;
  margin-left: 0;
  padding-left: 0;
}

ol.numbered-list li {
  display: block;
  margin-left: 2em;
}

ol.numbered-list li::before {
  display: inline-block;
  content: counter(item) ". ";
  counter-increment: item;
  width: 2em;
  margin-left: -2em;
}

ul.marked-list {
  margin-left: 0;
  padding-left: 0;
}

ul.marked-list li {
  display: block;
  margin-left: 2em;
}

ul.marked-list li:before {
    display: inline-block; 
    margin-left: -2em;
    content: '';
    display: block;
    width: 8px;
    height: 2px;
    background: #F9E32E;
    position: relative;
    bottom: -10px;
}

.anchor {
  padding-top: 45px;
  margin-top: -45px;
}

ul.anchor-links-list {
  list-style-type: none;
  padding-left: 0;
  li {
    margin-bottom: 10px;
  }
}
