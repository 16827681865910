body {
  margin: 0;
  font-family: 'Geometria', -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

@media screen and (min-width: 959px) {
  body {
    overflow-y: scroll;
  }
}
