.slider {
  background-color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: hidden;
  color: #000000;
  z-index: 100;
  padding-top: 20px;
  padding-top: calc(constant(safe-area-inset-top) + 5px);
  padding-top: calc(env(safe-area-inset-top) + 20px);

  .slider-item {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .img-cnt {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;

      .no-media {
        margin-top: 30px;
      }

      .popup {
        margin-top: 60px;

        @media screen and (min-height: 450px) {
          margin-top: 15vh !important;
        }

        @media screen and (min-height: 500px) {
          margin-top: 20vh !important;
        }

        @media screen and (min-height: 550px) {
          margin-top: 25vh !important;
        }
      }
    }

    .img {

      @media screen and (max-height: 600px) {
        &:not( .no-media) {
          margin-top: 10vh;
        }
      }
      @media screen and (max-height: 560px) {
        margin-top: 8vh;

        &:not( .no-media) {
          height: 220px;
        }
      }
      @media screen and (max-height: 500px) {
        margin-top: 8vh;

        &:not( .no-media) {
          height: 150px;
        }
      }
      @media screen and (max-height: 450px) {
        margin-top: 5vh;
        &:not( .no-media) {
          height: 120px;
        }
      }


      @media screen and (min-height: 600px) {
        margin-top: 10vh !important;
      }
      @media screen and (min-height: 700px) {
        margin-top: 20vh !important;
      }
      @media screen and (min-height: 900px) {
        margin-top: 30vh !important;
      }
    }

    .text {
      position: absolute;
      width: 100%;
      bottom: 21%;
      font-family: Geometria, sans-serif;
      font-style: normal;
      font-weight: 500;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      font-size: 18px;
      line-height: 31px;
      letter-spacing: -1.5px;

      & > span {
        width: 85%;
      }
    }
  }


  .btn-cnt {
    display: flex;
    justify-content: center;

    .btn {
      position: absolute;
      bottom: 9%;
      z-index: 100;
      width: 70%;
      border: 2px solid #662681;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0);
      font-family: Geometria, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 129%;
      letter-spacing: -0.5px;
    }
  }

}

.dots {
  z-index: 100;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 4%;
  width: 100%;
}

.dot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: yellow;
  margin: 0 10px;

  &.active {
    background-color: #662681;
  }
}
