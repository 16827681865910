.popup {
  display: flex;

  .close-button {
    position: absolute;
    top: 5%;
    right: 5%;
  }
}

.popup-dialog-cnt {
  & > .MuiDialog-container {
    & > .MuiDialog-paper {
      margin: 0;
      padding-top: 20px;
      height: 85%;
      overflow-y: hidden;
    }
  }
}

@media screen and (min-width: 959px) {
  .popup-dialog-cnt {
    & > .MuiDialog-container {
      & > .MuiDialog-paper {
        height: 70%;
        overflow-y: hidden;
      }
    }
  }
}
